import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'

export const FadeInUpCrop = memo(function FadeInUpCrop({
  children,
  delay,
}: any) {
  const [ref, isVisible] = useInView({ threshold: 0.8, triggerOnce: true })
  const variants = {
    visible: {
      y: '0%',
      transition: {
        delay: delay || 0,
        bounce: 0,
        duration: 2,
        type: 'spring',
      },
    },
    hidden: {
      y: '110%',
    },
  }

  return (
    <Wrapper ref={ref}>
      <motion.div
        animate={isVisible ? 'visible' : 'hidden'}
        variants={variants}
      >
        {children}
      </motion.div>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  overflow: hidden;
`
