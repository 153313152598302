import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { FadeInZoom } from 'app/components/Common/Animation/FadeInZoom'
import { FeatureProps, FeaturesList } from 'app/components/Common/FeaturesList'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  imageSmall?: ImageProps
  languageCode: string
  title?: string
  subtitle?: string
  description?: string
  guests?: string
  size?: string
  roomView?: string
  features?: FeatureProps[]
}

export const IntroRoom = memo(function IntroRoom({
  imageSmall,
  languageCode,
  title,
  subtitle,
  description,
  guests,
  size,
  roomView,
  features,
}: Props) {
  return (
    <Container>
      <Wrapper>
        <IntroLeft>
          <IntroLeftText>
            <FadeInUp>
              <Deco />
            </FadeInUp>
            {title ? (
              <FadeInUp>
                <Title dangerouslySetInnerHTML={{ __html: title }} />
              </FadeInUp>
            ) : null}
            {subtitle ? (
              <FadeInUp>
                <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
              </FadeInUp>
            ) : null}
            <FadeInUp>
              {' '}
              {description ? (
                <Text dangerouslySetInnerHTML={{ __html: description }}></Text>
              ) : null}
            </FadeInUp>
          </IntroLeftText>
          {imageSmall ? (
            <FadeInZoom>
              <ImageSmall {...imageSmall} />
            </FadeInZoom>
          ) : null}
        </IntroLeft>
        <IntroRight>
          <FadeInUp>
            <MainFeatures>
              {size ? (
                <IconHolder>
                  <Icon>
                    <img src="/ico_size.svg" />
                  </Icon>
                  <p>{size}</p>
                </IconHolder>
              ) : null}
              {guests ? (
                <IconHolder>
                  <Icon>
                    <img src="/ico_guests.svg" />
                  </Icon>
                  <p>{guests}</p>
                </IconHolder>
              ) : null}
              {roomView ? (
                <IconHolder>
                  <Icon>
                    <img src="/ico_view.svg" />
                  </Icon>
                  <p>{roomView}</p>
                </IconHolder>
              ) : null}
            </MainFeatures>
          </FadeInUp>
          <OtherFeatures>
            <FadeInUp>
              <OtherFeaturesTitle
                dangerouslySetInnerHTML={{
                  __html: useVocabularyData(
                    'room-features-title',
                    languageCode,
                  ),
                }}
              />
            </FadeInUp>
            <FadeInUp>
              {features && features.length > 1 ? (
                <FeaturesList features={features} />
              ) : null}
            </FadeInUp>
          </OtherFeatures>
        </IntroRight>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  width: 100%;
  margin: 8vw 0 6vw 0;

  @media (max-width: 991px) {
    margin: 30px 0;
  }
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;

  @media (max-width: 991px) {
    display: block;
  }
`

const Deco = styled.div`
  position: absolute;
  top: -6vw;
  left: -6vw;
  width: 13vw;
  height: 150%;
  min-height: 25vw;
  background-color: #ffffff;

  @media (max-width: 991px) {
    top: -40px;
    left: -30px;
    width: 120px;
    height: 120%;
  }
`

const IntroLeft = styled.div`
  width: 50%;
  position: relative;
  padding: 6vw 8vw 0vw 16vw;

  @media (max-width: 991px) {
    padding: 60px;
    width: 100% !important;
  }
`
const IntroLeftText = styled.div`
  position: relative;
`

const Title = styled.h2`
  font-weight: 400;
  font-size: 3vw;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  display: block;
  text-transform: uppercase;
  position: relative;

  @media (max-width: 991px) {
    font-size: 28px;
  }
`
const Subtitle = styled.h4`
  font-weight: 200;
  font-size: 2vw;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.subheading};
  position: relative;

  @media (max-width: 991px) {
    font-size: 22px;
  }
`

const Text = styled.div`
  max-width: 100%;
  width: 450px;
  margin-top: 3vw;
  position: relative;
`

const ImageSmall = styled(Image)`
  margin-top: 4vw;
  display: block;
  img {
    width: 50%;
    height: auto;
  }

  @media (max-width: 991px) {
    display: none;
  }
`

const IntroRight = styled.div`
  width: 50%;
  position: relative;
  padding: 6vw 8vw 4vw 9vw;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};

  @media (max-width: 991px) {
    width: 100%;
    padding: 60px 30px;
    margin-top: 30px;
  }
`

const MainFeatures = styled.div``

const IconHolder = styled.div`
  width: 6vw;
  text-align: center;
  margin-right: 4.8vw;
  display: inline-block;
  vertical-align: top;
  p {
    display: block;
    width: 100%;
    line-height: 19px;
  }

  @media (max-width: 991px) {
    width: 100%;
    display: flex;
    margin-right: 30px;
    align-items: center;

    p {
      text-align: left;
      padding-left: 30px;
      width: calc(100% - 25vw);
    }
  }
`
const Icon = styled.div`
  width: 6vw;
  height: 6vw;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.variants.neutralLight1};
  position: relative;
  margin-bottom: 1vw;

  img {
    width: 28%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  @media (max-width: 991px) {
    width: 18vw;
    height: 18vw;
    margin-bottom: 20px;
  }
`

const OtherFeatures = styled.div`
  margin-top: 6vw;
`
const OtherFeaturesTitle = styled.div`
  font-weight: 200;
  font-size: 1.6vw;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.subheading};
  position: relative;
  margin-bottom: 1vw;

  @media (max-width: 991px) {
    font-size: 22px;
    margin-bottom: 20px;
  }
`
