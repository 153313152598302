import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Arrow } from 'app/components/Common/Arrow'
import { useVocabularyData } from 'app/utils/vocabulary'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo } from 'react'

import { Props as RoomProps, RoomSlide } from './item'

export interface Props {
  pretitle?: string
  rooms: RoomProps[]
  pageID?: string
  languageCode: string
}

export const RoomsSlider = memo(function RoomsSlider({
  languageCode,
  rooms,
  pageID,
}: Props) {
  const [sliderRef, instanceRef] = useKeenSlider({
    slides: { perView: 2 },
    loop: true,
    breakpoints: {
      '(max-width: 991px)': {
        slides: { perView: 1 },
      },
    },
  })

  if (!rooms) {
    return null
  }

  return (
    <Container>
      <ElemsWrapper>
        <Deco />

        <FadeInUp>
          <Pretitle>
            {useVocabularyData('other-rooms-title', languageCode)}
          </Pretitle>
        </FadeInUp>

        <FadeInUp>
          <Slider ref={sliderRef} className="keen-slider">
            {rooms
              .filter((rooms) => rooms.id !== pageID)
              .map((elem, index) => (
                <Slide key={index} className="keen-slider__slide">
                  <React.Fragment key={index}>
                    <RoomSlide {...elem} />
                  </React.Fragment>
                </Slide>
              ))}
          </Slider>
        </FadeInUp>

        <Arrows>
          <ArrowLeft>
            <Arrow
              variant="only-icon-dark"
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
            />
          </ArrowLeft>

          <ArrowRight>
            <Arrow
              direction="R"
              variant="only-icon-dark"
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.next()
              }
            />
          </ArrowRight>
        </Arrows>
      </ElemsWrapper>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  width: 100%;
  margin: 10vw 0;
  padding: 0 10vw;

  @media (max-width: 991px) {
    margin: 80px 0;
    padding: 0 30px;
  }
`

const ElemsWrapper = styled.div`
  position: relative;
  padding: 0 6vw;

  @media (max-width: 991px) {
    padding: 0 20px;
  }
`

const Deco = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 13vw;
  height: 130%;
  transform: translate3d(0, -50%, 0);
  background-color: #ffffff;

  @media (max-width: 991px) {
    width: 80px;
  }
`

const Pretitle = styled.h3`
  font-size: 0.9vw;
  letter-spacing: 2px;
  margin-bottom: 3vw;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.subheading};
  position: relative;

  @media (max-width: 1439px) {
    font-size: 14px;
    margin-bottom: 30px;
  }
`

const Slider = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
`

const Slide = styled.div`
  padding-right: 3vw;

  @media (max-width: 991px) {
    padding-right: 10px;
  }
`

const Arrows = styled.div``

const ArrowLeft = styled.div`
  position: absolute;
  top: 50%;
  left: 3vw;
  transform: translate3d(-50%, -50%, 0);

  @media (max-width: 991px) {
    left: 0;
  }
`
const ArrowRight = styled.div`
  position: absolute;
  top: 50%;
  right: 6vw;
  transform: translate3d(50%, -50%, 0);

  @media (max-width: 991px) {
    right: 3vw;
  }
`
