import styled from '@emotion/styled'
import { GalleryRoom } from 'app/components/GalleryRoom'
import { Hero } from 'app/components/Hero'
import { IntroRoom } from 'app/components/IntroRoom'
import { ReserveRoom } from 'app/components/ReserveRoom'
import { RoomsSlider } from 'app/components/RoomsSlider'
import { SEO } from 'app/components/SEO'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function RoomPageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.structuredDataProps ? (
        <StructuredData
          languageCode={pageContext.languageCode}
          {...context.structuredDataProps}
        />
      ) : null}

      {context.headerProps ? (
        <Header
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          pageID={context.id}
          {...context.headerProps}
        />
      ) : null}

      {context.heroProps ? (
        <Hero variant="room" {...context.heroProps} />
      ) : null}
      {context.introProps ? (
        <IntroRoom
          languageCode={pageContext.languageCode}
          {...context.introProps}
        />
      ) : null}
      {context.galleryRoomProps ? (
        <GalleryRoom {...context.galleryRoomProps} />
      ) : null}
      <ReserveRoom
        languageCode={pageContext.languageCode}
        {...context.reserveRoomProps}
      />
      {context.roomsSliderProps ? (
        <RoomsSlider {...context.roomsSliderProps} />
      ) : null}

      {context.footerProps ? (
        <Footer languageCode={context.languageCode} {...context.footerProps} />
      ) : null}
    </Container>
  )
})

const Container = styled.main``
