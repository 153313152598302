import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { FadeInUpCrop } from 'app/components/Common/Animation/FadeInUpCrop'
import { Button } from 'app/components/Common/Button'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  urlRequest?: string
}

export const ReserveRoom = memo(function ReserveRoom({
  languageCode,
  urlRequest,
}: any) {
  const languagePrefix = languageCode.slice(0, 2)
  const hrefRequest =
    languagePrefix == 'it'
      ? '/' + urlRequest
      : '/' + languagePrefix + '/' + urlRequest
  return (
    <Container>
      <FadeInUpCrop>
        <DecoTitle>CORALLO</DecoTitle>
      </FadeInUpCrop>

      <FadeInUp>
        <Title
          dangerouslySetInnerHTML={{
            __html: useVocabularyData('room-ask-booking', languageCode),
          }}
        ></Title>
      </FadeInUp>

      <FadeInUp>
        <Buttons>
          <Button
            variant="default"
            label={useVocabularyData('book', languageCode)}
            URL={useVocabularyData('booking-link', languageCode)}
            target="_blank"
          />
          <Button
            variant="outline"
            label={useVocabularyData('request', languageCode)}
            URL={hrefRequest}
          />
        </Buttons>
      </FadeInUp>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  width: 100%;
  margin: 7vw 0;
  text-align: center;

  @media (max-width: 991px) {
    margin: 60px 0 80px 0;
  }
`
const DecoTitle = styled.h2`
  font-weight: 200;
  font-size: 11.5vw;
  line-height: 10vw;
  letter-spacing: 1vw;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.variants.neutralLight3};
  font-family: ${({ theme }) => theme.fontFamily.subheading};
  position: relative;
`

const Title = styled.h3`
  font-weight: 200;
  font-size: 2vw;
  text-transform: uppercase;
  margin: 3vw 0;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  position: relative;

  @media (max-width: 991px) {
    font-size: 22px;
    margin: 20px 0;
  }
`

const Buttons = styled.div`
  display: block;

  > a {
    display: inline-block;
    margin: 0 10px;
  }

  @media (max-width: 991px) {
    padding: 0 20px;
    > a {
      margin: 10px 0;
    }
  }
`
