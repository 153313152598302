import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  languageCode?: string
  title?: string
  images?: ImageProps[]
  claim?: string
  size?: string
  guests?: string
  room_view?: string
  slug?: string
  id: string
}

export const RoomSlide = memo(function RoomSlide({
  languageCode,
  title,
  images,
  size,
  guests,
  room_view,
  slug,
}: Props) {
  const href =
    languageCode != 'it' ? '/' + languageCode + '/' + slug : '/' + slug

  if (!images) return null
  return (
    <Container>
      <Link to={href}>
        <StyledImage {...images[0]} />
        <Header>
          {title ? (
            <Title dangerouslySetInnerHTML={{ __html: title }}></Title>
          ) : null}
        </Header>
        <RoomInfos>
          {size ? (
            <RoomInfo dangerouslySetInnerHTML={{ __html: size }}></RoomInfo>
          ) : null}
          {guests ? (
            <RoomInfo dangerouslySetInnerHTML={{ __html: guests }}></RoomInfo>
          ) : null}
          {room_view ? (
            <RoomInfo
              dangerouslySetInnerHTML={{ __html: room_view }}
            ></RoomInfo>
          ) : null}
        </RoomInfos>
      </Link>
    </Container>
  )
})

const Container = styled.article`
  position: relative;
  width: 100%;
`

const Header = styled.header``

const Title = styled.h3`
  font-weight: 400;
  font-size: 2vw;
  line-height: 2vw;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  display: block;
  text-transform: uppercase;

  @media (max-width: 991px) {
    font-size: 22px;
    line-height: 1;
  }
`

const RoomInfos = styled.ul`
  margin: 1vw 0 0 0;
  white-space: nowrap;

  @media (max-width: 991px) {
    display: none;
  }
`
const RoomInfo = styled.li`
  display: inline-block;
  font-size: 0.9vw;
  letter-spacing: 2px;
  margin-right: 1.5vw;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.subheading};
  position: relative;

  &::before {
    content: ' ';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: -1vw;
    transform: translate3d(0, -50%, 0);
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
  }

  &:first-child {
    &:before {
      display: none;
    }
  }

  @media (max-width: 1439px) {
    font-size: 14px;
  }
`

const StyledImage = styled(Image)`
  display: block;
  margin-bottom: 1.8vw;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 991px) {
    margin-bottom: 20px;
  }
`

const Claim = styled.p`
  margin: 20px 0;
`

const StyledButton = styled(Button)`
  margin: 0 auto;
`
