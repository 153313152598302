import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Arrow } from 'app/components/Common/Arrow'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useRef, useState } from 'react'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'

const AUTOPLAY_TIMER = 5000

interface ImagesProps {
  image?: ImageProps
}
export interface Props {
  galleryCaption?: string
  images: ImagesProps[]
  imagesMobile: ImagesProps[]
}

export const GalleryRoom = memo(function GalleryRoom({
  galleryCaption,
  images,
  imagesMobile,
}: Props) {
  const [details, setDetails] = useState<any>(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState<boolean[]>([])
  const [nextLoaded, setNextLoaded] = useState<boolean[]>([])
  const timer = useRef<any>()

  function positionStyle(idx: any) {
    if (!details) {
      return undefined
    }

    const position = details.slides[idx].distance
    const x = (galleryRef.current?.size || window.innerWidth) * -position
    return {
      transform: `translate3d(${x}px, 0px, 0px)`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
    }
  }

  const [sliderRef, galleryRef] = useKeenSlider({
    defaultAnimation: {
      duration: 1800,
    },
    loop: images.length > 1 ? true : false,
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    detailsChanged(s) {
      images.length > 1 ? setDetails(s.track.details) : null
    },
  })

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true
    const newNextLoaded = [...nextLoaded]
    newNextLoaded[currentSlide + 1] = true

    setLoaded(newLoaded)
    setNextLoaded(newNextLoaded)

    document.addEventListener('keydown', function (e) {
      switch (e.keyCode) {
        case 37:
          galleryRef.current?.prev()
          break
        case 39:
          galleryRef.current?.next()
          break
      }
    })

    timer.current = setInterval(() => {
      if (galleryRef) {
        galleryRef.current?.next()
      }
    }, AUTOPLAY_TIMER)

    return () => {
      clearInterval(timer.current)
    }
  }, [currentSlide, galleryRef, sliderRef])

  return (
    <Container>
      <FadeInUp>
        <Wrapper>
          <style type="text/css">{mediaStyle}</style>
          <MediaContextProvider>
            {/* <Slider ref={sliderRef} className="keen-slider">
              {images.map((item, index) => (
                <Slide key={index} className="keen-slider__slide">
                  <Media lessThan="tabletMedium">
                    <StyledImage {...imagesMobile[index].image} />
                  </Media>
                  <Media greaterThanOrEqual="tabletMedium">
                    <StyledImage {...item.image} />
                  </Media>
                </Slide>
              ))}
            </Slider> */}

            <Carousel ref={sliderRef}>
              {uniq(images).map((item, index) => (
                <ParallaxProvider>
                  <Parallax
                    className="slider-wrap"
                    translateY={['-20%', '20%']}
                  >
                    <Slide
                      key={index}
                      className={
                        currentSlide === index
                          ? 'keen-slider__slide active'
                          : 'keen-slider__slide'
                      }
                    >
                      <Inner style={positionStyle(index)}>
                        <Media lessThan="tabletMedium">
                          <StyledImage {...imagesMobile[index].image} />
                        </Media>
                        <Media greaterThanOrEqual="tabletMedium">
                          <StyledImage {...item.image} />
                        </Media>
                      </Inner>
                    </Slide>
                  </Parallax>
                </ParallaxProvider>
              ))}
            </Carousel>

            {images.length > 1 ? (
              <Arrows>
                <ArrowLeft>
                  <Arrow
                    variant="full-bg"
                    onClick={(e) =>
                      e.stopPropagation() || galleryRef.current?.prev()
                    }
                  />
                </ArrowLeft>

                <ArrowRight>
                  <Arrow
                    direction="R"
                    variant="full-bg"
                    onClick={(e) =>
                      e.stopPropagation() || galleryRef.current?.next()
                    }
                  />
                </ArrowRight>
              </Arrows>
            ) : null}
          </MediaContextProvider>
        </Wrapper>
      </FadeInUp>
      <FadeInUp>
        {galleryCaption ? (
          <GalleryCaption
            dangerouslySetInnerHTML={{ __html: galleryCaption }}
          />
        ) : null}
      </FadeInUp>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  width: 100%;
  padding: 0 10vw;
  margin: 8vw 0;

  @media (max-width: 991px) {
    margin: 60px 0;
    padding: 0 30px;
  }
`
const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const Slider = styled.div`
  position: relative;
  width: 100%;
  height: 48vw;
  overflow: hidden;
  display: flex;

  @media (max-width: 991px) {
    height: 70vw;
  }
`

const StyledImage = styled(Image)``

const Arrows = styled.div``

const ArrowLeft = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate3d(-50%, -50%, 0);

  @media (max-width: 991px) {
    left: 10px;
  }
`
const ArrowRight = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate3d(50%, -50%, 0);

  @media (max-width: 991px) {
    right: 10px;
  }
`

const Carousel = styled.div`
  top: 0;
  left: 0;
  display: flex;
  position: relative;
  width: 100%;
  height: 48vw;
  overflow: hidden;
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }

  @media (max-width: 991px) {
    height: 70vw;
  }
`

const Slide = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 3;
  }

  &.active {
    img {
      transition: 1.5s;
    }
  }
`

const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition-timing-function: cubic-bezier(0.76, 0, 0.24, 1);
`

const GalleryCaption = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  line-height: 1.1875rem;
  margin: 3rem auto 0;
  max-width: 62.5rem;
  text-align: center;
`
